import 'polyfill-object.fromentries'

import createCache from '@emotion/cache'
import { StylesProvider } from '@material-ui/core/styles' // eslint-disable-line no-restricted-imports
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/browser'
import * as React from 'react'
import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { InlineSpinner } from 'shared/components/Spinner'
import { redirectFromLegacyRoute } from 'shared/helpers/redirectFromLegacyRoute'
import { muiThemeV6 } from 'shared/theme'
import { StyleSheetManager } from 'styled-components'
import { TssCacheProvider } from 'tss-react'

import { PersistGate } from 'redux-persist/integration/react'
import { App } from './App'
import { AppContainer } from './AppContainer'
import { GlobalStyles } from './components/GlobalStyles/GlobalStyles'
import { detectEnvironment, getBranchKey, GTM_CONTAINER, isProduction, SENTRY_DSN } from './config/config'
import { handleUnauthorizedRequest, setCsrfHeader, setXHeaders } from './helpers/authHelper'
import { useIsPath } from './hooks/useIsPath'
import { MainErrorBoundary } from './pages/ErrorPage/MainErrorBoundary'
import { JobsNav } from './pages/Jobs/JobsNav'
import { persistor } from './redux/store/persistor'
import { store } from './redux/store/store'
import { marketingRoutesPaths } from './Routes'
import { loadBranch } from './util/branch'
import { loadGoogleAnalytics } from './util/googleAnalytics'
import { loadTagManager } from './util/googleTagManager'

const tssCache = createCache({
  key: 'tss',
})

redirectFromLegacyRoute()
loadGoogleAnalytics()
loadTagManager(GTM_CONTAINER)
loadBranch(getBranchKey())

if (isProduction) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE_VERSION,
    environment: detectEnvironment().toLowerCase(),
    allowUrls: [
      'app.cloudtrucks.com',
      'static.cloudtrucks.com',
      'app.cloudtrucks-staging.com',
      'static.cloudtrucks-staging.com',
    ],
    normalizeDepth: 7,

    replaysSessionSampleRate: 0,
    // @sentry/replay @LogRocket ffr
    // https://docs.sentry.io/platforms/javascript/session-replay/#verify
    replaysOnErrorSampleRate: 0.1,
    integrations: [new Sentry.Replay()],
  })

  Sentry.configureScope((scope) => {
    scope.setTag('React.version', React.version)
  })
}

// set up interceptor to set csrf token in the header for every request
setCsrfHeader()
setXHeaders()
// set interceptor to redirect user to login if authorized
handleUnauthorizedRequest()

const queryClient = new QueryClient()

ReactDOM.render(
  <TssCacheProvider value={tssCache}>
    <StylesProvider injectFirst>
      <StyleSheetManager target={document.querySelector('#custom-styles') as any}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MuiThemeProvider theme={muiThemeV6}>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <CssBaseline />
                  <GlobalStyles />
                  <CustomApp />
                </Router>
              </QueryClientProvider>
            </MuiThemeProvider>
          </PersistGate>
        </Provider>
      </StyleSheetManager>
    </StylesProvider>
  </TssCacheProvider>,
  document.getElementById('root')
)

function CustomApp() {
  const isMarketingPath = useIsPath(marketingRoutesPaths)
  const AppComponent = isMarketingPath ? AppContainer : App
  const location = useLocation()

  function renderSuspenseFallback() {
    const path = Object.keys(LazyComponentLoader).find((key) => location.pathname.startsWith(key))
    const LoaderWrap = path ? LazyComponentLoader[path] || React.Fragment : React.Fragment

    return (
      <AppComponent>
        <LoaderWrap>
          <InlineSpinner mt={5} mb={5} />
        </LoaderWrap>
      </AppComponent>
    )
  }

  return (
    <MainErrorBoundary>
      <Suspense fallback={renderSuspenseFallback()}>
        <AppComponent />
      </Suspense>
    </MainErrorBoundary>
  )
}

type LazyComponentLoaderType = Record<string, React.JSXElementConstructor<{ children: React.ReactNode }>>

export const LazyComponentLoader: LazyComponentLoaderType = {
  '/profile': function ProfileLoader({ children }) {
    return <>{children}</>
  },
  '/jobs/details': function JobsLoader({ children }) {
    return <>{children}</>
  },
  '/jobs/templates': function JobsLoader({ children }) {
    return <>{children}</>
  },
  '/jobs': function JobsLoader({ children }) {
    return (
      <>
        <JobsNav />
        {children}
      </>
    )
  },

  '/contacted-loads/': function JobsLoader({ children }) {
    return (
      <>
        <JobsNav />
        {children}
      </>
    )
  },
}
