import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import { isConsole } from 'shared/config/config'

import { TaskStatusEnum } from '../components/TaskList/TaskList'
import { toCamelCase } from '../helpers/utils'
import { FleetApplication } from '.'
import { ConnectedIntegration } from './connectedIntegration'
import { parsePlaidAccount, PlaidAccount } from './plaid'
import { FeatureId } from './productSubscription'
import { SlimTrailer } from './trailer'
import { SlimTruck } from './truck'
import { User } from './user'

export enum OnboardingChecklistName {
  SIGN_AGREEMENT = 'SIGN_AGREEMENT',
  DRIVER_APPLICATION = 'DRIVER_APPLICATION',
  FLEET_APPLICATION = 'FLEET_APPLICATION',
  FLEET_DETAILS = 'FLEET_DETAILS',
  FLEX_AGREEMENT = 'FLEX_AGREEMENT',
  FLEX_STARTER_SUBSCRIPTION = 'FLEX_STARTER_SUBSCRIPTION',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  ADD_DRIVERS = 'ADD_DRIVERS',
  ADD_INDIVIDUAL_DRIVER = 'ADD_INDIVIDUAL_DRIVER',
  TAX_INFO = 'TAX_INFO',
  ELD_SETUP = 'ELD_SETUP',
  EQUIPMENT_SETUP = 'EQUIPMENT_SETUP',
  EQUIPMENT_DOCUMENTS = 'EQUIPMENT_DOCUMENTS',
  ADD_INDIVIDUAL_EQUIPMENT = 'ADD_INDIVIDUAL_EQUIPMENT',
  ADD_INDIVIDUAL_TRUCK = 'ADD_INDIVIDUAL_TRUCK',
  ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS = 'ADD_INDIVIDUAL_EQUIPMENT_DOCUMENTS',
  BROKER_SETUP = 'BROKER_SETUP',
  ADD_INDIVIDUAL_BROKER = 'ADD_INDIVIDUAL_BROKER',
  PAYMENT_PROFILE_SETUP = 'PAYMENT_PROFILE_SETUP',
  BANK_ACCOUNT_INFO = 'BANK_ACCOUNT_INFO',
  CONFIRM_CT_CARD_INFO = 'CONFIRM_CT_CARD_INFO',
  STRIPE_PAYMENT_PROFILE = 'STRIPE_PAYMENT_PROFILE',
  CREDIT_APPLICATION = 'CREDIT_APPLICATION',
  INSURANCE_INFORMATION = 'INSURANCE_INFORMATION',
  EMPLOYMENT_HISTORY = 'EMPLOYMENT_HISTORY',
  CONTRACTOR_PAYMENTS_PROFILE = 'CONTRACTOR_PAYMENTS_PROFILE',
  PAYROLL_BANK_ACCOUNT = 'PAYROLL_BANK_ACCOUNT',
  ADD_CONTRACTOR = 'ADD_CONTRACTOR',
  CONTRACTOR_PERSONAL_PROFILE = 'CONTRACTOR_PERSONAL_PROFILE',
  CONTRACTOR_PERSONAL_DIRECT_DEPOSIT = 'CONTRACTOR_PERSONAL_DIRECT_DEPOSIT',
  CONTRACTOR_PERSONAL_DEBIT_CARD = 'CONTRACTOR_PERSONAL_DEBIT_CARD',
  MAINTENANCE_SET_ASIDE = 'MAINTENANCE_SET_ASIDE',
  FUEL_CARD = 'FUEL_CARD',
  ONBOARDING_CALL = 'ONBOARDING_CALL',
  SAFETY_REVIEW = 'SAFETY_REVIEW',
  WELCOME_PACKAGE = 'WELCOME_PACKAGE',
  ACTIVATION = 'ACTIVATION',
  DOT_INSPECTION = 'DOT_INSPECTION',
  PLATES = 'PLATES',
  TRUCK_SETUP = 'TRUCK_SETUP',
  INITIAL_BACKGROUND_CHECK = 'INITIAL_BACKGROUND_CHECK',
}

export enum BackgroundCheckVendor {
  CHECKR = 'CHECKR',
  HIRERIGHT = 'HIRERIGHT',
}

export interface OnboardingChecklist {
  name: OnboardingChecklistName
  context?: {
    user?: User
    provider?: ConnectedIntegration
    truck?: SlimTruck
    trailer?: SlimTrailer
    fleetApplication?: FleetApplication
    integrations?: ConnectedIntegration[]
    plaidAccount?: PlaidAccount
    backgroundCheckVendor?: BackgroundCheckVendor
    initial_screening_report_url?: string
  }
  status: TaskStatusEnum
  statusDetail?:
    | 'IN_REVIEW'
    | 'MISSING_INFORMATION'
    | 'WRONG_RECIPIENT_FOR_LEASE_AGREEMENT'
    | 'MISSING_INFORMATION_FOR_LEASE_AGREEMENT'
    | 'MISSING_BG_CHECK'
    | 'NOT_SUBMITTED'
    | 'CONTACT_CLOUDTRUCKS_REPRESENTATIVE'
    | 'COMPLETE_PREVIOUS_TASKS'
    | 'NOT_CASH_USER'
    | 'FLEET_NOT_ELIGIBLE'
    | 'SCREENING_COMPLETED'
    | 'SCREENING_APPOINTMENT_ATTENDED'
    | 'REVIEW_NEEDED'
    | 'COMPLETE_DRIVER_APPLICATION'
    | 'ORDER_CANCELLED'
    | 'ORDER_SUSPENDED'
  subitems: ReadonlyArray<OnboardingSubChecklist>
}

export type OnboardingSubChecklist = Omit<OnboardingChecklist, 'subitems'>

export interface OnboardingChecklistResult {
  applicationChecklist?: ReadonlyArray<OnboardingChecklist>
  roadPreparationChecklist?: ReadonlyArray<OnboardingChecklist>
  flexChecklist?: ReadonlyArray<OnboardingChecklist>
  cashChecklist?: ReadonlyArray<OnboardingChecklist>
  onboardingStatus?: {
    applicationPercentage: number
    header: string
    subheader?: string
  }
}

export function fetchUserOnboardingChecklist(
  userId: string,
  featureId?: FeatureId
): Promise<OnboardingChecklistResult | undefined> {
  const url = `/api/v3/users/${userId}/checklist/${featureId ? `?feature_id=${featureId}` : ''}`

  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        ...(response.data.flex_checklist && {
          flexChecklist: parseApplicationChecklist(response.data.flex_checklist),
        }),
        ...(response.data.cash_checklist && {
          cashChecklist: parseApplicationChecklist(response.data.cash_checklist),
        }),
        ...(response.data.application_checklist && {
          applicationChecklist: parseApplicationChecklist(response.data.application_checklist),
        }),
        ...(response.data.road_prep_checklist && {
          roadPreparationChecklist: parseApplicationChecklist(response.data.road_prep_checklist),
        }),
        onboardingStatus: {
          applicationPercentage: response.data.application_percentage,
          header: response.data.header,
          subheader: response.data.subheader,
        },
      }
    }
  })
}

const CONSOLE_ONBOARDING_CHECKLIST_SORT_ORDER = [
  OnboardingChecklistName.DRIVER_APPLICATION, // Driver application
  OnboardingChecklistName.INITIAL_BACKGROUND_CHECK, // Driving history check
  OnboardingChecklistName.EMPLOYMENT_HISTORY, // Employment history
  OnboardingChecklistName.FLEET_DETAILS, // Fleet details
  OnboardingChecklistName.EQUIPMENT_SETUP, // Add equipment
  OnboardingChecklistName.SIGN_AGREEMENT, // Sign Lease Agreement
  OnboardingChecklistName.EQUIPMENT_DOCUMENTS, // Equipment Documents
  OnboardingChecklistName.CONFIRM_CT_CARD_INFO, // Order card
  OnboardingChecklistName.BACKGROUND_CHECK, // Drug screen | Background check and drug screen
  OnboardingChecklistName.ONBOARDING_CALL, // Onboarding webinar
  OnboardingChecklistName.TRUCK_SETUP, // Equipment documents
  OnboardingChecklistName.PAYMENT_PROFILE_SETUP, // CT Cash card
  OnboardingChecklistName.FUEL_CARD, // Fuel card
  OnboardingChecklistName.MAINTENANCE_SET_ASIDE, // Maintenance fund (optional)
  OnboardingChecklistName.SAFETY_REVIEW, // Safety review
  OnboardingChecklistName.WELCOME_PACKAGE, // Onboarding package
  OnboardingChecklistName.ACTIVATION, // Activation
]

const APP_ONBOARDING_CHECKLIST_SORT_ORDER = [
  OnboardingChecklistName.DRIVER_APPLICATION, // Driver application
  OnboardingChecklistName.INITIAL_BACKGROUND_CHECK, // Driving history check
  OnboardingChecklistName.EMPLOYMENT_HISTORY, // Employment history
  OnboardingChecklistName.FLEET_DETAILS, // Fleet details
  OnboardingChecklistName.EQUIPMENT_SETUP, // Add equipment
  OnboardingChecklistName.SIGN_AGREEMENT, // Sign Lease Agreement
  OnboardingChecklistName.EQUIPMENT_DOCUMENTS, // Equipment documents
  OnboardingChecklistName.TRUCK_SETUP, // Equipment documents
  OnboardingChecklistName.CONFIRM_CT_CARD_INFO, // Order card
  OnboardingChecklistName.PAYMENT_PROFILE_SETUP, // CT Cash card
  OnboardingChecklistName.FUEL_CARD, // Fuel card
  OnboardingChecklistName.ONBOARDING_CALL, // Onboarding webinar
  OnboardingChecklistName.BACKGROUND_CHECK, // Drug screen | Background check and drug screens
  OnboardingChecklistName.SAFETY_REVIEW, // Safety review
  OnboardingChecklistName.WELCOME_PACKAGE, // Onboarding package
  OnboardingChecklistName.ACTIVATION, // Activation
  OnboardingChecklistName.MAINTENANCE_SET_ASIDE, // Maintenance fund (optional)
]

function parseApplicationChecklist(
  checklist: ReadonlyArray<Omit<OnboardingChecklist, 'statusDetail'> & { status_detail: string }>
): ReadonlyArray<OnboardingChecklist> {
  return checklist
    .map((c: any) => ({
      ...c,
      statusDetail: c.status_detail,
      context: parseContext(c.context),
      subitems: c.subitems.map((s: any) => ({
        ...s,
        context: parseContext(s.context),
      })),
    }))
    .sort((a, b) =>
      isConsole()
        ? CONSOLE_ONBOARDING_CHECKLIST_SORT_ORDER.indexOf(a.name) -
          CONSOLE_ONBOARDING_CHECKLIST_SORT_ORDER.indexOf(b.name)
        : APP_ONBOARDING_CHECKLIST_SORT_ORDER.indexOf(a.name) -
          APP_ONBOARDING_CHECKLIST_SORT_ORDER.indexOf(b.name)
    )
}

function parseContext(context: any) {
  return {
    ...context,
    ...(context?.fleet_application
      ? { fleetApplication: parseFleetApplication(context.fleet_application) }
      : undefined),
    ...(context?.user ? { user: toCamelCase(context.user) } : undefined),
    ...(context?.truck ? { truck: toCamelCase(context.truck) } : undefined),
    ...(context?.trailer ? { trailer: toCamelCase(context.trailer) } : undefined),
    ...(context?.plaid_account ? { plaidAccount: parsePlaidAccount(context.plaid_account) } : undefined),
    ...(context?.background_check_vendor
      ? { backgroundCheckVendor: toCamelCase(context.background_check_vendor) }
      : undefined),
  }
}

function parseFleetApplication(fleetApplication: any): FleetApplication {
  return {
    businessType: fleetApplication.business_type,
    businessDocumentation: fleetApplication.business_documentation,
    certificateOfInsurance: fleetApplication.certificate_of_insurance,
    operatingAuthorityLetter: fleetApplication.operating_authority_letter,
  }
}

export interface MaintenanceSetAside {
  id: string
  teamId: string
  enabled: boolean
  dateLastEnabled: string
  currentBalance: string
  weeklyMax: string
  jobPercentageMax: string
  totalContributions: string
  totalWithdrawals: string
  userEditable: boolean
  recommendations: {
    jobPercentageMax: number
    weeklyMax: number
    last30DaysMilesDriven: number
  }
}

export function fetchMaintenanceSetAside(): Promise<MaintenanceSetAside | undefined> {
  return axios.get(`/api/v3/cash/maintenance-set-aside/`).then((response) => {
    if (response.status === HttpStatus.OK) {
      return toCamelCase(response.data) as MaintenanceSetAside
    }
  })
}

export function updateMaintenanceSetAside(
  id: string,
  weeklyMax: string
): Promise<MaintenanceSetAside | undefined> {
  return axios
    .put(`/api/v3/cash/maintenance-set-aside/${id}/`, { weekly_max: weeklyMax })
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return toCamelCase(response.data) as MaintenanceSetAside
      }
    })
}

export function createMaintenanceSetAside(weeklyMax: string): Promise<MaintenanceSetAside | undefined> {
  return axios.post(`/api/v3/cash/maintenance-set-aside/`, { weekly_max: weeklyMax }).then((response) => {
    if (response.status === HttpStatus.OK) {
      return toCamelCase(response.data) as MaintenanceSetAside
    }
  })
}
