import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import { PaginatedResults } from 'shared/helpers/typeHelper'
import type {
  OverrideVehicleKeeptruckinData,
  OverrideVehiclesWithKeeptruckinDataPayload,
  TruckPayload,
} from 'shared/models/truck'
import { createEldFieldsPayload, overrideVehiclesWithKeeptruckinData, parseTruck } from 'shared/models/truck'

import { Equipment } from './equipment'

export type { TruckPayload, OverrideVehicleKeeptruckinData, OverrideVehiclesWithKeeptruckinDataPayload }
export { parseTruck, createEldFieldsPayload, overrideVehiclesWithKeeptruckinData }

// Models

export enum ELD {
  KEEP_TRUCKIN = 'KEEP_TRUCKIN',
  SAMSARA = 'SAMSARA',
  OTHER = 'OTHER',
}

export const EldType = {
  KEEP_TRUCKIN: 'KeepTruckin',
  SAMSARA: 'Samsara',
  OTHER: 'Other',
}

export enum EldTypeStatus {
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  VALID = 'VALID',
}

export interface Truck extends Equipment {
  eldType?: ELD
  eldSerialNumber?: string
  insuranceNonTruckingLiability?: string
  insuranceNonTruckingLiabilityExpiration?: string
  iftaRequired?: boolean
  platesProgramRequired?: boolean
  isInfoComplete?: boolean
  isBaseInfoComplete?: boolean
  isDocumentsComplete?: boolean
  isEldComplete?: boolean
  isIftaAndPlatesComplete?: boolean
  eldStatus?: EldTypeStatus
  eldFields?: {
    licensePlateNumber: EldField
    licensePlateState: EldField
    vehicleMake: EldField
    vehicleModel: EldField
    vehicleYear: EldField
    vehicleNumber: EldField
  }
  assignedDrivers?: {
    id: string
    firstName: string
    lastName: string
  }[]
}

export enum EldFieldState {
  MISSING_IN_CT = 'MISSING_IN_CT',
  INCOMPATIBLE = 'INCOMPATIBLE',
  VALID = 'VALID',
}

export interface EldField {
  state: EldFieldState
  value: string
}

// Apis

export function createTruck(payload: TruckPayload): Promise<Truck> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .post(`/api/v1/trucks/`, payload.formData || payload, config)
    .then((response) => {
      if (response.status === HttpStatus.CREATED) {
        return parseTruck(response.data)
      }
      throw response.data
    })
    .catch((err) => {
      // Temp fix for server side 500
      if (typeof err?.response?.data === 'string')
        // eslint-disable-next-line no-throw-literal
        throw { data: { message: 'Something went wrong' } }
      throw err
    })
}

export function fetchTrucks(page = 1): Promise<PaginatedResults<Truck>> {
  return axios.get(`/api/v1/trucks/?page=${page}`).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        ...response.data,
        results: response.data.results.map(parseTruck),
      }
    }

    throw response.data
  })
}

export function fetchTruck(id: string): Promise<Truck> {
  return axios.get(`/api/v1/trucks/${id}/`).then((response) => {
    if (response.status === HttpStatus.OK) {
      return parseTruck(response.data)
    }

    throw response.data
  })
}

export function updateTruck(payload: TruckPayload): Promise<Truck> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .patch(`/api/v1/trucks/${payload.id}/`, payload.formData || payload, config)
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return parseTruck(response.data)
      }
      throw response.data
    })
}

export function deleteTruck(truckId: string) {
  return axios.delete(`/api/v1/trucks/${truckId}/`)
}

// Helpers
export function getTruckMissingFields(truck: Truck) {
  const errors = [
    !truck.isBaseInfoComplete,
    !truck.isDocumentsComplete,
    !truck.isIftaAndPlatesComplete,
  ].filter(Boolean)

  if (errors.length > 1) {
    return 'Missing information'
  }

  if (!truck.isBaseInfoComplete) {
    return 'Missing truck information'
  }

  if (!truck.isDocumentsComplete) {
    return 'Missing documents'
  }

  if (!truck.isIftaAndPlatesComplete) {
    return 'Missing IFTA and plates'
  }

  return ''
}
