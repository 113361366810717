import { useSelector } from 'react-redux'

import { currentUserSelector } from '../redux/selectors/users'

export function useHasFeatureFlag(flag: string) {
  const user = useSelector(currentUserSelector)
  return Boolean(user?.featureFlags?.[flag])
}

export function useHasPlaidBankAccountFlag() {
  return useHasFeatureFlag('plaid-bank-account')
}

export function useHasReplacePlaidAccountFlag() {
  return useHasFeatureFlag('plaid-replace-account')
}

export function useHasDisableMcDotValidationFlag() {
  return useHasFeatureFlag('disable-mc-dot-validation')
}

export function useContractorBusinessAllowAnyAdminOnboard() {
  return useHasFeatureFlag('public-contractor-business-allow-any-admin-onboard')
}

export function useSkipPermissionCheckFeature() {
  return useHasFeatureFlag('skip-permission-check')
}

export function useHasOldSyncSearchFlag() {
  return useHasFeatureFlag('old-sync-search')
}

export function useHasSkipStripeCardChecksFlag() {
  return useHasFeatureFlag('skip-stripe-card-checks')
}

export function useHasRecommendedSort() {
  return useHasFeatureFlag('p-rex-show-in-front-end')
}

export function useHasAnnualBilling() {
  return useHasFeatureFlag('public-annual-billing')
}

export function useHasHosEtaBrokerTrackingFlag() {
  return useHasFeatureFlag('hos_etas_for_self_service_job_tracking')
}

export function useHasContractorPaymentsCtCashBalanceInstantPayEnabled() {
  return useHasFeatureFlag('public-contractor-payments-ct-cash-balance-instant-pay-enabled')
}

export function useOauthDisablePopupFlag() {
  return useHasFeatureFlag('public-oauth-disable-popup')
}

export function useHasCoreOnboardingVisibilityFlag() {
  return useHasFeatureFlag('core-onboarding-visibility-flag')
}

export function useHasCoreOnboardingVisibilityFlagV2() {
  // Searcha and delete also `publicCoreOnboardingVisibilityV2Flag` when deleting this flag
  return useHasFeatureFlag('public-core-onboarding-visibility-v2-flag')
}

export function useHasFuelCardMultiCardFlag() {
  return useHasFeatureFlag('public-fuel-card-multi-card')
}
