import { createTheme, Theme } from '@material-ui/core/styles' // eslint-disable-line no-restricted-imports
import { createTheme as createThemeV6, Theme as ThemeV6 } from '@mui/material'

import { bgGray, black, cyan, grey4d, primaryBlack, red, royalBlue } from './colors'

export const sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  extraLarge: 'extraLarge',
} as const

export const variants = {
  primary: 'primary',
  secondary: 'secondary',
  flat: 'flat',
  text: 'text',
} as const

//TODO remove material ui theme after we move to style components

function themeFactory(createThemeFactory: typeof createTheme | typeof createThemeV6) {
  const defaultTheme = createThemeFactory()
  // A custom theme for this app
  return createThemeFactory({
    palette: {
      primary: {
        main: royalBlue,
      },
      secondary: {
        main: cyan,
      },
      background: {
        default: bgGray,
      },
      error: {
        main: red,
      },
    },

    typography: {
      fontFamily: 'Overpass, sans-serif',
      fontWeightRegular: 400,
      body1: {
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '1.56',
        color: black,
      },
      subtitle1: {
        fontSize: '20px',
        fontWeight: 300,
        color: black,
      },
      subtitle2: {
        fontSize: '24px',
        lineHeight: '1.68',
        fontWeight: 600,
        color: black,
      },
      h1: {
        fontSize: '60px',
        fontWeight: 600,
        lineHeight: '92px',
        color: grey4d,
      },
      h5: {
        fontSize: '24px',
        lineHeight: '1.54',
        fontWeight: 600,
        color: primaryBlack,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '16px',
        },
      },
    },
    spacing: 8,

    overrides: {
      MuiContainer: {
        maxWidthLg: {
          maxWidth: 1440,
        },
      },
      MuiStepIcon: {
        root: {
          '&$completed': {
            color: cyan,
          },
          '&$active': {
            color: cyan,
          },
        },
        active: {},
        completed: {},
      },

      MuiStepLabel: {
        label: {
          fontSize: '13px',
          lineHeight: '20px',
          letterSpacing: '2px',
          fontWeight: 300,
          color: primaryBlack,
          textTransform: 'uppercase',
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: '10px',
            lineHeight: '15px',
          },
        },
      },

      MuiFormLabel: {
        root: {
          '&$focused': {
            color: black,
          },
        },
      },

      MuiFormHelperText: {
        root: {
          '&$error': {
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '18px',
            letterSpacing: '0.02em',
          },
          color: black,
        },
      },

      MuiTextField: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
      },

      MuiFilledInput: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        },
      },
      MuiSelect: {
        root: {
          '&.MuiSelect-select:focus': {
            backgroundColor: '#f4f7f9',
          },
        },
      },
    },
  })
}

export const muiTheme = themeFactory(createTheme) as Theme
export const muiThemeV6 = themeFactory(createThemeV6) as ThemeV6

export const colorMappings: Record<string, string> = {
  primary: muiTheme.palette.primary.main,
  secondary: muiTheme.palette.secondary.main,
}
