import Toolbar from '@mui/material/Toolbar'
import { breakpoints } from 'shared/theme/breakpoints'
import { bgGray } from 'shared/theme/colors'
import { styled } from 'styled-components'

import logo from 'assets/images/CT-logo-small.svg'
import { CLOUDTRUCKS_MARKETING_PAGE_URL } from 'helpers/constants'

export const BasicNav = () => {
  const openCTMarketingPage = () => {
    window.location.href = CLOUDTRUCKS_MARKETING_PAGE_URL
  }

  return (
    <StyledToolbar>
      <Img src={logo} alt="CloudTrucks Logo" onClick={openCTMarketingPage} />
    </StyledToolbar>
  )
}

const StyledToolbar = styled(Toolbar)`
  background-color: ${bgGray};
  justify-content: space-between;
  min-height: 70;
  max-width: 1440;
  margin: auto;
`

const Img = styled.img`
  width: 49px;
  height: 32px;
  cursor: pointer;

  @media ${breakpoints.sm} {
    height: 24px;
  }
`
