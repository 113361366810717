import axios from 'axios'
import * as HttpStatus from 'http-status-codes'

import { toCamelCase } from '../helpers/utils'
import { Equipment, EquipmentPayload, parseEquipment } from './equipment'

export const MAX_DOT_INSPECTION_IN_DAYS = 90

export enum ELD {
  KEEP_TRUCKIN = 'KEEP_TRUCKIN',
  SAMSARA = 'SAMSARA',
  OTHER = 'OTHER',
}

export enum EldTypeStatus {
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  VALID = 'VALID',
}

export enum EldFieldState {
  MISSING_IN_CT = 'MISSING_IN_CT',
  INCOMPATIBLE = 'INCOMPATIBLE',
  VALID = 'VALID',
}

export interface EldField {
  state: EldFieldState
  value: string
}

interface EldVehicle {
  providerName: string
  providerUrl: string
}

export enum TruckType {
  SLEEPER = 'SLEEPER',
  DAY_CAB = 'DAY_CAB',
}

export const TruckTypeLabel: Record<TruckType, string> = {
  [TruckType.SLEEPER]: 'Sleeper',
  [TruckType.DAY_CAB]: 'Day Cab',
}

export interface Truck extends Equipment {
  eldType?: ELD
  eldSerialNumber?: string
  eldVehicles?: EldVehicle[]
  insuranceNonTruckingLiability?: string
  insuranceNonTruckingLiabilityExpiration?: string
  iftaRequired?: boolean
  platesProgramRequired?: boolean
  isInfoComplete?: boolean
  isBaseInfoComplete?: boolean
  isDocumentsComplete?: boolean
  isEldComplete?: boolean
  isIftaAndPlatesComplete?: boolean
  eldStatus?: EldTypeStatus
  eldFields?: {
    licensePlateNumber: EldField
    licensePlateState: EldField
    vehicleMake: EldField
    vehicleModel: EldField
    vehicleYear: EldField
    vehicleNumber: EldField
  }
  assignedDrivers?: {
    id: string
    firstName: string
    lastName: string
  }[]
  truckInspectionReport?: {
    id: string
    inspectionReport: any
  }
  drivers?: {
    id: string
    fullName: string
  }[]
  odometerReadingMi?: number
  odometerReadingMiDate?: Date
}

export interface SlimTruck {
  id?: string
  vehicleRegistration?: string
  insuranceNonTruckingLiability?: string
  lastDotInspection?: string
  insurancePhysicalDamage?: string
  licensePlateNumber?: string
  licensePlateState?: string
  vehicleNumber?: string
  vehicleMake?: string
  vehicleModel?: string
  vehicleYear?: string | number
  vinNumber?: string | number
}

export interface EquipmentEldFieldsPayload {
  license_plate_number: EldField
  license_plate_state: EldField
  vehicle_make: EldField
  vehicle_model: EldField
  vehicle_year: EldField
  vehicle_number: EldField
}

export interface TruckPayload extends EquipmentPayload {
  eld_serial_number?: string
  eld_type?: ELD
  insurance_non_trucking_liability?: string
  insurance_non_trucking_liability_expiration?: string
  ifta_required?: boolean
  plates_program_required?: boolean
  is_info_complete?: boolean
  is_base_info_complete?: boolean
  is_documents_complete?: boolean
  is_eld_complete?: boolean
  is_ifta_and_plates_complete?: boolean
  eld_status?: EldTypeStatus
  eld_fields?: EquipmentEldFieldsPayload
  assigned_drivers?: {
    id: string
    first_name: string
    last_name: string
  }[]
}

export const getDocumentsInitialErrors = (equipment: SlimTruck) => {
  const {
    vehicleRegistration,
    insuranceNonTruckingLiability,
    lastDotInspection,
    insurancePhysicalDamage,
    licensePlateNumber,
    licensePlateState,
  } = equipment

  const errors: Array<string> = []

  if (!vehicleRegistration) {
    errors.push('Vehicle registration')
  }

  if (!insuranceNonTruckingLiability) {
    errors.push('Non-trucking liability insurance')
  }

  if (!insurancePhysicalDamage) {
    errors.push('Physical damage insurance')
  }

  if (!lastDotInspection) {
    errors.push(`Clean DOT inspection from past ${MAX_DOT_INSPECTION_IN_DAYS} days`)
  }

  if (!licensePlateNumber || !licensePlateState) {
    errors.push(`License plate`)
  }

  return errors
}

export function getTruckV3(id: string | undefined): Promise<{ truck: Truck | undefined }> {
  return axios
    .get(`/api/v3/trucks/${id}/`)
    .then(({ data }) => ({ truck: data ? (toCamelCase(data) as Truck) : undefined }))
}

export function getTeamTrucksV3(teamId: string | undefined): Promise<{ trucks: Truck[] }> {
  return axios
    .get(`/api/v3/trucks/?team_id=${teamId}`)
    .then(({ data }) => ({ trucks: data?.results ? (toCamelCase(data.results) as Truck[]) : [] }))
}

export function createTruckV3(userId: string, payload: TruckPayload): Promise<Truck> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .post(`/api/v3/trucks/?user_id=${userId}`, payload.formData || payload, config)
    .then((response) => {
      if (response.status === HttpStatus.CREATED) {
        return parseTruck(response.data)
      }
      throw response.data
    })
    .catch((err) => {
      // Temp fix for server side 500
      if (typeof err?.response?.data === 'string')
        // eslint-disable-next-line no-throw-literal
        throw { data: { message: 'Something went wrong' } }
      throw err
    })
}

export function updateTruckV3(userId: string, payload: TruckPayload): Promise<Truck> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios
    .patch(`/api/v3/trucks/${payload.id}/?user_id=${userId}`, payload.formData || payload, config)
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return parseTruck(response.data)
      }
      throw response.data
    })
}

export function deleteTruckV3(truckId: string) {
  return axios.delete(`/api/v3/trucks/${truckId}/`)
}

export function parseTruck(truck: TruckPayload): Truck {
  return {
    ...parseEquipment(truck),
    eldType: truck.eld_type,
    eldSerialNumber: truck.eld_serial_number,
    insuranceNonTruckingLiability: truck.insurance_non_trucking_liability,
    insuranceNonTruckingLiabilityExpiration: truck.insurance_non_trucking_liability_expiration,
    iftaRequired: truck.ifta_required,
    platesProgramRequired: truck.plates_program_required,
    isInfoComplete: truck.is_info_complete,
    isBaseInfoComplete: truck.is_base_info_complete,
    isDocumentsComplete: truck.is_documents_complete,
    isIftaAndPlatesComplete: truck.is_ifta_and_plates_complete,
    assignedDrivers: truck?.assigned_drivers?.map((d) => ({
      id: d.id,
      firstName: d.first_name,
      lastName: d.last_name,
    })),
    eldStatus: truck.eld_status,
    eldFields: truck.eld_fields
      ? {
          licensePlateNumber: truck.eld_fields.license_plate_number,
          licensePlateState: truck.eld_fields.license_plate_state,
          vehicleMake: truck.eld_fields.vehicle_make,
          vehicleModel: truck.eld_fields.vehicle_model,
          vehicleYear: truck.eld_fields.vehicle_year,
          vehicleNumber: truck.eld_fields.vehicle_number,
        }
      : undefined,
  }
}

export interface OverrideVehicleKeeptruckinData {
  id: string
  fields: Partial<Record<keyof EquipmentEldFieldsPayload, EldField>>
}

export interface OverrideVehiclesWithKeeptruckinDataPayload {
  trucks: OverrideVehicleKeeptruckinData[]
}

export function createEldFieldsPayload(fields: Truck['eldFields']): OverrideVehicleKeeptruckinData['fields'] {
  if (!fields) return {}
  return {
    license_plate_number: fields.licensePlateNumber,
    license_plate_state: fields.licensePlateState,
    vehicle_make: fields.vehicleMake,
    vehicle_model: fields.vehicleModel,
    vehicle_year: fields.vehicleYear,
    vehicle_number: fields.vehicleNumber || {
      state: EldFieldState.VALID,
      value: '',
    },
  }
}

export function overrideVehiclesWithKeeptruckinData(
  payload: OverrideVehiclesWithKeeptruckinDataPayload
): Promise<void> {
  return axios.post(`/api/v1/trucks/override-vehicles-with-keeptruckin-data/`, payload).then((response) => {
    return response.data
  })
}
